// footer
.footer-widgets,
.site-footer {
  background: transparent;
  .widget-area {
    .widget {
      margin: 0;
    }
  }
}
#footer-widgets {
  // border-top: 3px $black solid;
  padding: 0;
  background-color: $main;
  padding-top: 150px;
  @include pad{
    padding-top: 50px;
  }
  .container {
    max-width: 100%;
    width: 100%;
    padding: 0;
  }
  .footer-widget.widget_nav_menu {
    padding: 0;
    margin: 0;
    @include container;
    .menu {
      display: flex;
      flex-wrap: wrap;
      padding: 0 90px;
      margin-bottom: 35px;
      justify-content: space-between;
      @include media(max,900) {
        padding: 0;
      }
      @include pad {
        margin-bottom: 15px;
      }
      &:after {
        display: none;
      }
      li {
        margin: 0;
        a {
          display: block;
          color: $black;
          text-decoration: none;
          font-size: 0.875rem;
          line-height: normal;
        }        // customer
        &.pll-parent-menu-item {
          display: none;
        }
        &.nw-only-submenu {
          > a {
            display: none;
          }
          .sub-menu {
            padding: 0;
          }      // 第一層
        }
      }
      > li {
        @include phone {
          width: 100%;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: solid 1px $border;
        }
        &:last-of-type {
          border-bottom: none;
        }
        > a {
          margin-bottom: 10px;
          font-weight: bold;
          @include phone {
            margin-bottom: 0;
          }      // 子選單
        }
      }
      .sub-menu {
        @include phone {
          display: flex;
          flex-wrap: wrap;
          padding-top: 6px;
          margin-bottom: -5px;
        }
        li {
          margin-bottom: 8px;
          @include phone {
            width: 50%;
          }  // 隱藏
        }
      }
    }
  }
  h1{
    @include container;
    margin-bottom: 40px;
    a{
      display: block;
      max-width: 250px;
      @include pad{
        max-width: 150px;
        margin: 0 auto;
      }
      img{
        display: block;
        width: 100%;
      }
    }
  }
  p.copyright {
    text-align: center;
    background-color: black;
    color: #fff;
    font-size: 0.875rem;
    padding: 10px 0;
    color: $white;
    a{
      color: $white;
    }
  }
  // cookie
  .nw-cookie-container {
    padding: 20px 130px 20px 20px;
    border-radius: 5px;
    position: fixed;
    bottom: 30px;
    right: 50%;
    transform: translate(50%,0);
    width: calc(100% - 40px);
    max-width: 800px;
    background-color: #fff;
    border: solid 1px $border;
    z-index: 1000;
    @include phone {
      padding: 15px;
      bottom: 0;
      border-radius: 0;
      transform: none;
      right: 0;
      width: 100%;
    }
    p,
    a {
      font-size: 0.875rem;
      color: $text;
    }
    p {
      @include phone {
        padding-bottom: 10px;
        border-bottom: solid 1px $border;
      }
    }
    button {
      padding: 5px 0;
      position: absolute;
      border: none;
      background: none;
      bottom: 50%;
      right: 40px;
      outline: none;
      color: $link;
      font-weight: bold;
      transform: translateY(50%);
      @include phone {
        display: block;
        margin: 10px auto 0;
        font-weight: 500;
        width: 100%;
        background-color: $link;
        border-radius: 5px;
        color: #fff;
        position: relative;
        bottom: 0;
        right: 0;
        transform: none;
      }
    }
  }
}