.nw-page-banner{
  position: relative;
  .kc_single_image{
    img{
      display: block;
      width: 100%;
    }
  }
  .kc_text_block{
    position: absolute;
    width: auto;
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);
    z-index: 3;
    background-color: rgba(255,255,255,0.8);
    white-space: nowrap;
    h2{
      border: solid 1px #707070;
      padding: 25px 80px;
      text-align: center;
      font-size: rem(30);
      font-weight: 500;
      span{
        display: block;
        margin-top: 10px;
        color: $orange;
        font-size: rem(24);
        letter-spacing: 3px;
      }
    }
  }
}