@include app(page-contact){
  .wpcf7-form{
    display: flex;
    flex-wrap: wrap;
  }
  .app-field{
    margin-bottom: 10px;
    input{
      width: 100%;
    }
    textarea{
      width: 100%;
    }
    select{
      width: 100%;
    }
    > label{
      margin-bottom: 5px;
      font-size: rem(16);
      font-weight: normal;
      color: $black;
    }
  }
  .app-field--btn{
    display: flex;
    align-items: center;
    justify-content: center;
    input{
      background-color: $orange;
      font-size: rem(16);
      font-weight: normal;
    }
  }
}