// 文章
body.archive.category,
body.search.search-results:not(.woocommerce-page) {
  #content-inside {
    @include container;
    display: flex;
    flex-wrap: wrap;
    padding-top: 150px;
    @include pad {
      padding-top: 30px;
    }
  }
   #main {
    display: flex;
    flex-wrap: wrap;
  }
  .post {
    width: 100%;
    @include pad {
      padding: 0;
      width: calc(50% - 8px);
      border: none;
      @include phone {
        width: 100%;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: solid 1px rgba(#000,.1);
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &:nth-of-type(odd) {
        margin-right: 16px;
        @include phone {
          margin-right: 0;
        }
      }
    }
    .list-article-thumb {
      display: block;
      margin-right: 0;
      width: 30%;
      @include pad {
        width: 100%;
        margin-bottom: 15px;
      }
      img,
      a {
        display: block;
        width: 100%;
      }
    }
    .list-article-content {
      width: 70%;
      padding-left: 30px;
      @include pad {
        width: 100%;
        padding-left: 0;
      }
    }
    .list-article-meta {
      margin-bottom: 0;
      a {
        font-size: 0.9rem;
        color: $main;
        font-weight: normal;
      }
      span.date {
        display: block;
        font-size: 0.85rem;
        color: $orange;
        font-weight: normal;
      }
    }
    .entry-header {
      margin-bottom: 10px;
    }
    .entry-title {
      a {
        color: $text;
        font-weight: 500;
        font-size: rem(24);
        letter-spacing: 1px;
      }
    }
    .entry-excerpt,
    .entry-summary {
      p {
        font-size: 1rem;
        color: $black;

      }
      .clearfix {
        @include clearfix;
      }
      a.read-more {
        text-transform: capitalize;
        display: block;
        margin-top: 10px;
        float: right;
        text-decoration: none;
        font-size: 0.875rem;
        color: $orange;
        &:hover {
          color: $main;
        }
      }
    }
  }
}
.wp-pagenavi {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 40px 0 50px;
  * {
    font-size: 1rem;
    color: $black;
  }
  span:not(.pages),
  a:hover {
    background-color: $main;
    color: #fff;
  }
  span:not(.pages),
  a:not(.last):not(.first) {
    display: block;
    margin: 0 3px;
    @include size(25px);
    border-radius: 50%;
    text-align: center;
    text-decoration: none;
    line-height: 25px;
    @include t(.3);
  }
  a.last,a.first{
    background: none;
    color: $black;
    @include phone{
      display: none;
    }
  }
  span.pages {
    margin-right: 15px;
    @include phone{
      margin-bottom: 10px;
      margin-right: 0;
      width: 100%;
      text-align: center;
    }
  }
  a.nextpostslink {
    margin-left: 10px;
  }
  a.previouspostslink {
    margin-right: 10px;
  }
}
body.archive.category{
  .nw-page-banner + #content-inside{
    padding-top: 50px;
    @include pad{
      padding-top: 40px;
    }
  }
  .post{
    border: none;
    margin-bottom: 30px;
    padding: 20px!important;
    box-shadow: 2px 2px 8px #ccc;
    border-radius: 10px;
    
  }
}
body.single-post {
  #content-inside {
    @include container;
    padding: 150px 0 80px;
    max-width: 800px;
    @include pad {
      padding: 30px 0;
    }
  }
  .nw-page-banner + #content-inside{
    padding-top: 50px;
    @include pad{
      padding-top: 40px;
    }
  }
  .entry-header {
    .entry-title {
      font-size: 1.7rem;
      color: $black;
    }
    .byline {
      display: none;
    }
  }
  .entry-footer,
  .entry-meta {
    span,
    a {
      font-size: 0.8rem;
      color: $gray;
      font-weight: normal;
    }
  }
  .entry-footer{
    margin-top: 30px;
  }
  .entry-content {
    section.kc_row {
      padding: 0;
      .kc-row-container.kc-container {
        width: 100%;
      }
    }
  }
  .relpost-thumb-wrapper {
    float: left;
    left: -6px;
    position: relative;
    width: calc( 100% + 12px );
    h3 {
      padding: 6px;
      margin-bottom: 10px;
      font-weight: normal;
    }
    .relpost-block-container {
      width: 100%;
      float: left;
      a.relpost-block-single {
        width: calc(100% / 3 - 0.1px);
        background: none;
        @include phone {
          margin-bottom: 20px;
          padding-right: 0;
          width: 100%;
        }
      }
      .relpost-custom-block-single,
      .relpost-block-single-image {
        min-width: 100%;
        height: auto !important;
      }
      .relpost-custom-block-single {
        margin: 0;
      }
      .relpost-block-single-image {
        padding-bottom: 63%;
        background-size: cover !important;
        background-position: center center !important;
      }
      .relpost-block-single-text {
        padding-top: 10px;
        text-align: left;
        font-size: 0.9rem;
        color: $black;
      }
    }
  }
}
.post-btns {
  display: flex;
  justify-content: space-between;
  & > div {
    width: 48%;
    a {
      display: block;
      font-size: 1rem;
      color: $black;
      text-decoration: none;
      span {
        display: block;
      }
    }
    &.next {
      text-align: right;
    }
  }
}