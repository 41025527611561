//color
$main: #1A384D;
$hover: #118ab2;
$border: #dddddd;
$orange: #F08012;
$black: #3C3C3C;
$red: #ef476f;
$gray: #A1A1A1;
$white: #ffffff;
// other color
$text: #1A1A1A;
$link: #118ab2;
$titleIcon: #118ab2;
$special: #ffd166;
$message: #06d6a0;
$sent: #06d6a0;
$success: #06d6a0;
$error: #ef476f;
$info: #118ab2;
$title: #118ab2;
$background: #f5f5f5;
// btn color
$btnBg: #118ab2;
$btnColor: #fff;
// #264653,#2a9d8f,#e9c46a,#f4a261,#e76f51

$boxShadow: 0 4px 6px #cccccc;