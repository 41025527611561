.kc_text_block.app-home-title{
  margin-bottom: 40px;
  h2{
    font-size: rem(20);
    letter-spacing: rem(2);
    padding-left: rem(2);
    color: $black;
    line-height: rem(30);
  }
  h3{
    font-size: rem(16);
    line-height: rem(24);
    color: $main;
  }
  &:after{
    content: "";
    display: block;
    margin: 5px auto 0;
    background-color: $black;
    @include size(1px,25px);
  }
}

@include app(carousel){
  padding: 0;
  .kc-container.kc-row-container{
    max-width: 100%;
    padding: 0;
  }
  img{
    display: block;
    width: 100%;
  }
  .owl-controls{
    margin: 0;
    .owl-pagination{
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      .owl-page{
        margin: 0 10px;
        span{
          margin: 0;
          background-color: $main;
          @include size(10px);
        }
      }
    }
  }
}

@include app(home-newest){
  .kc-container.kc-row-container{
    &:after{
      content: "";
      display: block;
      border-top: solid 1px $border;
      margin-bottom: -40px;
      margin-top: 40px;
      @include pad{
        margin-bottom: 0;
      }
    }
  }
  .kc_row_inner{
    margin-left: -20px;
    margin-bottom: 50px;
    width: calc(100% + 40px);
    @include pad{
      margin-left: -6px;
      margin-bottom: 10px;
      width: calc(100% + 12px);
    }
    > div{
      padding: 0 20px;
      margin-bottom: 30px;
      @include pad{
        padding: 0 6px;
        width: 50%;
      }
    }
    h5{
      box-shadow: 0px 0px 5px #1A384DCC;
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      img{
        display: block;
        position: absolute;
        right: 50%;
        bottom: 50%;
        transform: translate(50%,50%);
        @include size(100%);
        object-fit: cover;
      }
      &:after{
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }
    h4{
      padding-left: 10px;
      margin-bottom: 15px;
      font-weight: normal;
      line-height: 1;
      font-size: rem(18);
      color: $black;
      border-left: solid 3px $black;
    }
    h3{
      padding-top: 20px;
      text-align: center;
      font-weight: normal;
      font-size: rem(16);
    }
  }
}

@include app(home-product){
  padding: 0;
  .home-product{
    padding: 50px 0;
    margin-top: -40px;
    margin-left: -2px;
    float: left;
    width: calc(100% + 4px);
    position: relative;
    @include pad{
      margin-left: -6px;
      width: calc(100% + 12px);
    }
    &:before{
      content: "";
      display: block;
      position: absolute;
      bottom: 50%;
      right: 50%;
      transform: translate(50%,50%);
      background-color: #535353;
      @include size(100vw,100%);
    }
  }
  .swiper-container{
    z-index: 2;
  }

  .swiper-slide{
    width: 25%;
    padding: 0 2px;
    @include pad{
      width: 50%;
      padding: 0 6px;
    }
    a{
      display: block;
      text-decoration: none;
    }
    h5{
      margin-bottom: 15px;
      position: relative;
      overflow: hidden;
      img{
        position: absolute;
        bottom: 50%;
        right: 50%;
        transform: translate(50%,50%);
        object-fit: cover;
        @include size(100%);
      }
      &:after{
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }
    h3{
      border-left: solid 1px $white;
      border-right: solid 1px $white;
      text-align: center;
      color: $white;
      font-weight: normal;
      @include t;
    }
    &:hover{
      h3{
        color: $orange;
      }
    }
  }
  .swiper-button-next, .swiper-button-prev{
    color: $white;    
    @include media(max,1200){
      display: none;
    }
  }
  .swiper-button-next{
    right: -50px;
  }
  .swiper-button-prev{
    left: -50px;
  }
  .swiper-pagination{
    display: none;
    margin-top: 30px;
    position: relative;
    bottom: 0;
    width: 100%;
    span{
      margin: 0 3px;
      background-color: #fff;
    }
    @include media(max,1200){
      display: block;
    }
  }
}

@include app(home-news){
  .list-post-type{
    display: flex;
    flex-wrap: wrap;
  }
  .list-item{
    padding: 40px 50px 0;
    margin: 0;
    width: 50%;
    border: none;
    position: relative;
    @include pad{
      padding: 40px 30px 0;
    }
    @include phone{
      padding: 30px 0 20px;
      margin-bottom: 25px;
      width: 100%;
      border-bottom: solid 1px $border;
    }
    .text,figure{
      display: none;
    }
    h3{
      margin-bottom: 15px;
      a{
        font-size: rem(18);
        line-height: rem(28);
        text-decoration: none;
        font-weight: normal;  
      }
    }
    .kc-read-more{
      padding: 0;
      color: $orange;
      border: none;
      font-size: rem(18);
      font-style: italic;
      text-decoration: none;
      background: none;
      @include phone{
        float: right;
      }
      &:after{
        margin-left: 15px;
        content: "»";
      }
      &:hover{
        color: $main;
      }
    }
    .kc-entry_meta{
      padding: 0;
      position: absolute;
      top: 0;
      left: 50px;
      @include pad{
        left: 30px;
      }
      @include phone{
        left: 0px;
      }
      .date{
        display: inline-block;
        padding: 3px 10px;
        line-height: 1;
        font-size: rem(14);
        border: solid 1px $main;
        color: $main;
        i{
          display: none;
        }
      }
    }
    &:hover{
      h3{
        a{
          color: $orange;
        }
      }
    }
  }
}

@include app(home-about){
  padding: 0;
  position: relative;
  .kc-container.kc-row-container{
    max-width: 100%;
    padding: 0;
  }
  .kc_single_image{
    @include mobile{
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%);
    }
    img{
      display: block;
      width: 100%;
      @include mobile{
        height: 100%;
        object-fit: cover;
        object-position: right center;
      }
    }
  }
  .kc_text_block{
    padding: 60px 80px;
    box-shadow: 1px 1px 5px $gray;
    background-color: $white;
    position: absolute;
    z-index: 2;
    top: 60px;
    left: calc((100vw - 1045px) / 2);
    max-width: 540px;
    @include media(max,1140){
      left: 35px;
    }
    @include mobile{
      position: relative;
      top: 0;
      left: 0;
      max-width: 100%;
      background-color: rgba($white,0.6);
      backdrop-filter: blur(2px);
    }
    @include phone{
      padding: 40px 20px;
    }
    p{
      font-size: rem(18);
      color: $black;
      line-height: rem(32);
      letter-spacing: 1px;
    }
    h2{
      font-size: rem(20);
      letter-spacing: rem(2);
      padding-left: rem(2);
      color: $black;
      line-height: rem(30);
    }
    h3{
      margin-bottom: 50px;
      font-size: rem(16);
      line-height: rem(24);
      color: $main;
      @include phone{
        margin-bottom: 20px;
      }
    }
    h6{
      margin-top: 30px;
      a{
        font-size: rem(16);
        line-height: 1;
        font-style: italic;
        font-weight: bold;
      }
    }
  }
}