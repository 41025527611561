#app-sidebar-box{
  padding: 0;
}

#nw-cover{
  &.sidebar-active{
    @include mobile{
      left: 0;
      animation: nw-fade 1s both;
      transition-delay: 0s;
    }
  }
}

#content-inside.works-tax-content{
  @include container;
  display: flex;
  flex-wrap: wrap;
  padding-top: 150px;
  background-image: url(/wp-content/uploads/about-bg.png);
  background-size: 40% auto;
  background-repeat: no-repeat;
  background-position: right center;
  @include mobile{
    padding-top: 100px;
  }
  @include pad {
    padding-top: 30px;
  }
  #primary{
    display: flex;
    flex-wrap: wrap;
  }
  #sidebar-control{
    display: none;
    padding: 5px 10px;
    margin-bottom: 20px;
    border-radius: 3px;
    width: fit-content;
    background-color: $orange;
    color: $white;
    border: none;
    font-size: rem(18);
    @include mobile{
      display: block;
    }
    i{
      margin-right: 10px;
    }
  }
  #app-sidebar-box{
    width: 260px;
    @include mobile{
      @include t;
      padding: 20px;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 99;
      background-color: $white;
      transform: translateX(-100%);
      &.sidebar-active{
        transform: translateX(0);
      }
    }
    .sidebar-close{
      display: none;
      padding: 0;
      // @include size(30px);
      border: none;
      border-radius: 0;
      background: none;
      outline: none;
      margin-top: -20px;
      margin-bottom: 10px;
      font-size: rem(25);
      color: $orange;
      text-align: center;
      float: right;
      @include mobile{
        display: block;
      }
    }
    form{
      margin-bottom: 20px;
      width: 100%;
      position: relative;
      border: solid 1px $black;
      overflow: hidden;
      input{
        margin: 0;
        padding: 5px 10px;
        width: 100%;
        background-color: $white;
        border: none;
        box-shadow: none;
        font-size: rem(16);
        outline: none;
        &[type="submit"]{
          padding: 0;
          position: absolute;
          top: 0;
          right: 3px;
          @include size(30px,100%);
          background-image: url(/wp-content/uploads/icon_search.svg);
          font-size: 0;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 20px auto;
        }
      }
    }
    .widget_nav_menu{
      ul.menu{
        padding: 0;
        margin: 0;
        background-color: $background;
        @include mobile{
          background: none;
        }
        li{
          margin: 0;
          list-style-type: none;
          a{
            display: block;
            padding: 10px 15px;
            font-size: rem(18);
            color: $black;
            text-decoration: none;
            line-height: rem(30);
            @include mobile{
              padding: 10px 0;
            }
          }
          &.current-menu-item,&:hover{
            > a{
              color: $hover;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  #main{
    padding-left: 50px;
    width: calc(100% - 260px);
    @include mobile{
      padding-left: 0;
      width: 100%;
    }
    > h2{
      padding-bottom: 5px;
      margin-bottom: 20px;
      border-bottom: solid 1px $main;
      width: 100%;
      font-size: rem(20);
    }
  }
  .products{
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    width: calc(100% + 16px);
    .product{
      padding: 0 8px;
      margin-bottom: 20px;
      width: floor(calc(100% / 3)*100)*0.01;
      border: none;
      @include phone{
        width: 50%;
      }
      .list-article-thumb{
        display: block;
        margin: 0;
        width: 100%;
        a{
          display: block;
          position: relative;
          &:after{
            content: "";
            display: block;
            padding-bottom: 100%;
          }
          img{
            position: absolute;
            bottom: 50%;
            right: 50%;
            transform: translate(50%,50%);
            object-fit: cover;
            @include size(100%);
          }
        }
      }
      .list-article-content{
        display: block;
        width: 100%;
        .entry-title{
          padding-top: 10px;
          a{
            display: block;
            text-align: center;
            font-size: rem(18);
          }
        }
      }
    }
  }
}

.nw-page-banner + #content-inside.works-tax-content{
  padding-top: 50px;
  @include pad{
    padding-top: 40px;
  }
}

@include app(product-info){
  padding: 0 0 80px;
  > .kc-container.kc-row-container{
    padding: 0;
  }
  .kc_row_inner{
    padding-bottom: 50px;
    .kc-sync2.owl-carousel{
      position: absolute;
      margin-left: 15px;
      left: 100%;
      bottom: 0;
      width: calc(100% - 25px);
      @include pad{
        margin-left: 0;
        position: relative;
        left: 0;
        width: 100%;
      }
      .owl-item{
        opacity: 0.6;
        padding-right: 5px;
        min-width: 60px;
        .item{
          border-radius: 8px;
          border: none;
          cursor: pointer;
          overflow: hidden;
        }
        &.synced{
          opacity: 1;
        }
      }
    }
    .kc_text_block{
      font-size: rem(16);
      color: $black;
      letter-spacing: 1px;
      @include pad{
        padding-top: 30px;
      }
      h1{
        font-size: rem(24);
        color: $main;
      }
      hr{
        margin: 10px 0;
        border-top-color: $orange;
      }
      h3{
        margin-bottom: 15px;
        font-size: rem(18);
        font-weight: 500;
      }
      p{
        margin-bottom: 10px;
      }
      &:after{
        content: "";
        display: block;
        padding-bottom: 28%;
        @include pad{
          display: none;
        }
      }
    }
  }
  .kc_text_block.product-info{
    > * + h3{
      margin-top: 40px;
    }
    h3{
      color: $orange;
      font-weight: 500;
    }
    hr{
      margin: 10px 0;
      max-width: 500px;
    }
    p{
      font-size: rem(18);
      line-height: rem(36);
      color: $black;
    }
  }
}