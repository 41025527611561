%triangle{
  &:after{
    content: "";
    display: block;
    margin-left: 5px;
    @include size(0);
    border-top: solid 7px $white;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }
}

.site-header {
  position: static;
  line-height: normal;
  background-color: rgba($main,.85);
  @include pad{
    background-color: $main;
  }
  .header-right-wrapper {
    float: right;
  }
  .container {
    position: static;
  }
  .search-btn{
    float: right;
    margin: 30px 0 0 20px;
    @include size(16px);
    border: none;
    background: none;
    background-image: url(/wp-content/uploads/magnifying-glass.svg);
    background-size: 16px auto;
    background-repeat: no-repeat;
    background-position: center center;
    outline: none;
    @include mobile{
      position: absolute;
      left: 20px;
      bottom: 50%;
      transform: translateY(50%);
      margin: 0;
      @include size(20px);
      background-size: 20px auto;
    }
  }
}


#header-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  @include pad{
    position: relative;
  }
}

#site-navigation {
  @include clearfix;
}
.site-branding {
  padding: 18px 0;
  @include t(0.3);
  @include mobile{
    margin: 0 auto;
    float: none;
    width: fit-content;
  }
  @include pad{
    padding: 10px 0;
  }
  .site-text-logo {
    display: block;
    // background-image: url(/wp-content/uploads/default.png);
    background-image: url(/wp-content/uploads/logo_header.svg);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    font-size: 0;
    @include size(180px,40px);
  }
}
      
.main-navigation{
  // position: relative
  @include mobile { 
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
  }
  .onepress-menu {
    display: flex;
    border: none;
    // border-top: solid 1px rgba($white,0.2);
    background: none;
      // background-color: #333
      // 左側滑入
    @include mobile {
      display: block;
      // padding-bottom: 80px;
      // flex-direction: column;
      // border: none;
      // overflow: auto;
      // position: absolute;
      // left: -275px;
      // top: 0;
      // height: 100vh;
      // width: 100%;
      // max-width: 275px;
      // z-index: 3;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    li {
      float: none;
      a {
        display: block;
        font-size: 16px;
        line-height: normal;
        font-weight: normal;
        text-align: center;
        color: $white;
        @include mobile {
          text-align: left;
          border-bottom: solid 1px rgba($white,0.1);
        }
        @include phone{
          font-size: 14px;
        }
      }
      &:hover,
      &.current-menu-item {
        > a {
          color: $white;
        }
      }
    }
    // 第一層
    > li {
      @include mobile{
        background-color: rgba($main,0.85);
        border: none;
      }
      > a {
        display: flex;
        align-items: center;
        padding: 30px 20px;
        line-height: 1;
        @include mobile {
          padding: 15px 20px;
        }      
      }
      &.menu-item-has-children{
        > a{
          @extend %triangle;
          @include mobile{
            &:after{
              display: none;
            }
          }
        }
      }
      // 二層選單
      > .sub-menu {
        @include mobile(min) {
          border-top: solid 1.5px $orange;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      // 最後項目第三層至左
      &:last-of-type,
      &.nw-third-left {
        .sub-menu {
          .sub-menu {
            @include mobile(min) {
              left: auto;
              right: 100%;
            }
          }
        }
      }
    }
    // 子選單樣式
    .sub-menu {
      display: none;
      width: 100%;
      min-width: 130px;
      border: none;
      background-color: $main;
      @include mobile {
        position: static;
      }
      li {
        a {
          padding: 10px 0px;
          background-color: transparent;
          border-top: solid 1px rgba($white,0.1);
          @include mobile{
            padding: 10px 20px;
            border: none;
            color: $main;
          }
        }
        &:first-of-type{
          > a{
            border-top: none;
          }
        }
        &:hover{
          > a{
            background-color: $white;
            color: $black;
          }
        }
      }
      .nav-toggle-subarrow{
        border-left-color: #e9e9e9;
        &:after{
          border-top-color: $main;
        }
      }
    }
    // 子選單
    li.menu-item-has-children {
      &:hover {
        @include mobile(min) {
          > .sub-menu {
            display: block;
            animation: fade 0.5s both;
          }
        }
      }
    }
    .nav-toggle-subarrow {
      position: absolute;
      padding: 0;
      right: 0;
      top: 0;
      z-index: 2;
      cursor: pointer;
      border-left: solid 1px rgba($white,0.1);
      color: $white;
      @include size(47px);
      @include mobile {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @include phone {
        @include size(45px);
      }
      @extend %triangle;
      i{
        display: none;
      }
    }
    .nav-toggle-dropdown > .nav-toggle-subarrow {
      &:after{
        transform: rotate(180deg);
      }
    }
  }
}

#nav-toggle {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 25px;
  z-index: 999;
  font-size: 0;
  @include size(35px);
  transform: translateY(-50%);
  span {
    border-radius: 50px;
    background-color: $white;
    &:before,
    &:after {
      transform-origin: 50% 50%;
      border-radius: 50px;
      background-color: $white;
    }
  }
  &.nav-is-visible {
    // background-color: rgba(#fff,.3);
    // border-radius: 50%;
    span {
      &:before,
      &:after {
        top: 0;
        left: 0;
        background-color: $white;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

#nw-toggle-btn {
  display: none;
  margin: 0;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  font-size: 0;
  z-index: 999;
  @include t(0.8);
  @include size(35px);
  @include mobile {
    display: block;
  }
  span {
    display: block;
    margin: 16px auto;
    @include size(25px,3px);
    background-color: $black;
    position: relative;
    border-radius: 50px;
    @include t(.3);
    &:before,
    &:after {
      content: "";
      display: block;
      @include size(25px,3px);
      background-color: $black;
      transform-origin: 50% 50%;
      position: absolute;
      left: 0;
      @include t(.3);
      border-radius: 50px;
    }
    &:before {
      top: -8px;
    }
    &:after {
      top: 8px;
    }  
    // &.nw-active {
    //   right: 300px;
    //   span {
    //     background-color: transparent;
    //     &:before,
    //     &:after {
    //       top: 0;
    //       left: 0;
    //       background-color: #fff;
    //     }
    //     &:before {
    //       transform: rotate(45deg);
    //     }
    //     &:after {
    //       transform: rotate(-45deg);
    //     }
    //   }
    // }
  }
}

.search-bar{
  display: none;
  position: absolute;
  top: 76px;
  left: 0;
  width: 100%;
  z-index: 2;
  border-bottom: solid 1px $main;
  @include pad{
    position: relative;
    top: 0;
  }
  form{
    display: flex;
  }
  input{
    border: none;
    margin: 0;
    border-radius: 0;
  }
  input[name="s"]{
    padding: 5px 25px;
    outline: none;
    border: none!important;
    box-shadow: none;
    background-color: rgba(#fff,0.85);
    width: calc(100% - 40px);
    height: 40px;
    font-size: rem(14);
    letter-spacing: 1px;
    &::placeholder{
      color: $gray;
    }
  }
  input[type="submit"]{
    padding: 0;
    font-size: 0;
    @include size(40px);
    background-image: url(/wp-content/uploads/magnifying-glass.svg);
    background-color: rgba($main,0.85);
    background-size: 20px auto;
    background-repeat: no-repeat;
    background-position: center center;
  }
}