// 預設值
// :root{

// }
  
* {
  font-family: 'Helvetica','Noto Sans TC','Microsoft Jhenghei', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-family: 'Helvetica','Noto Sans TC','Microsoft Jhenghei', sans-serif;
}
textarea:not(.editor-post-title__input):focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="email"]:focus,
input[type="month"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="week"]:focus {
  border-bottom-color: #efefef !important;
}
input[type="radio"],
input[type="checkbox"] {
  margin-left: 0;
}
select,
.woocommerce-ordering select {
  appearance: none;
  padding: 5px 35px 5px 15px;
  font-size: 0.85rem;
  color: $gray;
  background-image: url(/wp-content/uploads/down-filled-triangular-arrow.svg);
  background-size: 10px auto;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
}
.container {
  margin: 0 auto;
}
html {
  font-size: 16px;
  @include pad {
    font-size: 14px;
  }
  @include media(max,350) {
    font-size: 12px;
  }
}
body {  // animation: fade .5s 1s both
  &.nw-menu-in,
  &.nw-sidebar-in {
    overflow: hidden;
    #page {
      padding-left: 275px;
      width: calc(100% + 275px);
    }
  }
  &.nw-menu-in {
    .main-navigation .onepress-menu {
      z-index: 1000;
    }
    #nw-toggle-btn {
      right: 300px;
      span {
        background-color: transparent;
        &:before,
        &:after {
          top: 0;
          left: 0;
          background-color: #fff;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
    .menu-toggle-btn {
      margin-left: -25px;
    }
  }
  &.nw-sidebar-in {
    #shop-menu,
    #secondary {
      left: 0;
      z-index: 1000;
    }
  }
}

#nw-cover {
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 2;
  transition-delay: 0.8s;
  background-color: rgba(#000,0.7);
  animation: nw-fadeOut 0.8s both;
  cursor: pointer;
  @include size(100%);
  &.nw-active {
    transition-delay: 0s;
    animation: nw-fade 0.8s both;
    left: 0;
  }
}

#page {
  padding-left: 0;
  width: 100%;
  overflow: hidden;
  // left: 0
  @include t(0.8);
}

#nw-loading {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%);
  background-color: #fff;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .dots {
    display: flex;
    align-items: center;
    overflow: hidden;
    // border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-bottom: 70px;
    span {
      display: block;
      margin: 0;
      background-color: #333;
      border-radius: 0;
      animation: nw-load-dot 0.5s infinite alternate;
      position: relative;
      transform-origin: 50% 50%;
      @include size(10px,50px);
      $dots: $main,$orange,$main,$orange,$main;
      @for $i from 1 through 5 {
        &:nth-of-type(#{$i}) {
          animation-delay: #{($i - 1) * 0.2 + 's'};
          background-color: nth($dots, $i);
        }
      }
    }
  }
  // &.end {
  //   .dots {
  //     span {
  //       animation-name: nw-load-dot-end;
  //       animation-fill-mode: both;
  //       animation-duration: 2s;
  //     }
  //   }
  // }
}

#content-inside {
  max-width: 100%;
  width: 100%;
}
#content {
  min-height: 0;
  background-color: $white;
  @include t(0.5);
}
#primary,
#content-inside,
.kc-container {
  padding: 0;
}
.container,
.site-header .container {
  @include container;
}
.container,
.kc-col-container,
.kc-wrap-columns {
  @include clearfix;
}
.kc-container,
.kc_column,
.kc_column_inner {
  min-height: 0;
}
div.kc_row_inner {
  display: flex;
  flex-wrap: wrap;
}
.kc-video-inner {
  position: relative;
  padding-bottom: 56.2%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%!important);
  }
}

.kc_image_gallery.kc-grid .item-grid.grid-1 {
  width: 100%;
}
.breadcrumbs {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: $background;
  span[property="name"] {
    font-size: 1rem;
    line-height: 1.6rem;
    color: $black;
    font-weight: normal;
  }
  a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    width: fit-content;
    &:after {
      @include size(0.5rem);
      display: block;
      top: calc(50% - 0.3rem);
      right: -10px;
      transform: rotate(45deg);
      border-left: none;
      border-bottom: none;
      border-top: solid 1px $black;
      border-right: solid 1px $black;
      @include media(min,720) {
        right: -20px;
      }
    }
    &:before {
      display: none;
    }
  }
}
.entry-content {
  margin-bottom: 0;
}
// 頁面標題
.page-header {
  background-color: #fff;
  .container {
    *:last-child {
      margin-bottom: 0;
    }
  }
  .entry-title {
    margin-bottom: 10px;
    font-size: 25px;
    letter-spacing: 5px;
    line-height: normal;
    @include pad {
      font-size: 20px;
      letter-spacing: 3px;
    }
  }
  .entry-tagline {
    font-style: normal;
    p {
      font-size: 16px;
      margin: 0;
      letter-spacing: 3px;
      @include pad {
        font-size: 14px;
      }
      @include phone {
        font-size: 12px;
        letter-spacing: 1px;
      }
    }
  }
  &.page--cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0;
    background-size: cover;
    min-height: calc(100vw / 3);
    .entry-title {
      margin-bottom: 10px;
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 5px;
      line-height: normal;
      @include pad {
        margin-bottom: 5px;
        font-size: 30px;
      }
      @include phone {
        font-size: 20px;
      }
    }
    .entry-tagline {
      font-style: normal;
      p {
        margin: 0;
        letter-spacing: 3px;
      }
    }
  }
}

// 原系統光箱調整
div.pp_pic_holder.pp_pic_holder {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%,-50%);
  position: fixed;
  width: auto !important;
  max-width: 900px;
  img,
  .pp_content,
  .pp_hoverContainer,
  .pp_details {
    display: block;
    width: 100% !important;
    height: auto !important;
    max-height: 80vh;
    max-width: 100%;
    object-fit: contain;
  }
  a {
    text-decoration: none;
  }
}
.pp_content_container.pp_content_container {
  .pp_left,
  .pp_right {
    padding-left: 0;
    padding-right: 0;
  }
}

// 表單認證調整
// .c4wp-display-captcha-form.c4wp-display-captcha-form {
//   display: flex;
//   flex-direction: row-reverse;
//   justify-content: flex-end;
//   position: relative;
//   width: 200px;
//   br,
//   strong {
//     display: none;
//   }
//   .refresh_captcha {
//     display: block;
//     @include size(15px);
//     position: absolute;
//     left: calc(100% + 5px);
//     bottom: 50%;
//     transform: translateY(50%);
//     img {
//       display: block;
//       margin: 0;
//       float: none;
//       @include size(100%,auto );
//     }
//   }
//   input[type="text"].c4wp_user_input_captcha {
//     margin: 0;
//     padding: 10px;
//     font-size: 1rem;
//     width: 100px;
//     &:focus {
//       outline: none;
//     }
//   }
// }

// 搜尋樣式
.search-form,
.woocommerce-product-search {
  display: flex;
  label {
    display: block;
    width: calc(100% - 40px);
    flex-grow: inherit;
    -webkit-box-flex: inherit;
    &.screen-reader-text {
      display: none;
    }
  }
  input[type="search"] {
    padding: 8.5px 15px;
    width: calc(100% - 40px);
    font-size: 1rem;
    color: $black;
    outline: none;
    border-radius: 0;
  }
  [type="submit"] {
    margin: 0;
    padding: 0;
    font-size: 0;
    @include size(40px);
    background-color: $btnBg;
    border: none;
    background: $btnBg url(/wp-content/uploads/magnifying-glass.svg) no-repeat center center;
    background-size: 20px auto;
    &:hover {
      background: $btnBg url(/wp-content/uploads/magnifying-glass.svg) no-repeat center center;
      background-size: 20px auto;
      opacity: 1;
    }
  }
}

.fancybox-slide--video {
  .fancybox-content {
    margin: 0;
    height: 0;
    max-height: 0;
    position: relative;
    padding-bottom: 45%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
} 



// 版面架構初始值
.nw-flex {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  width: calc(100% + 30px);
  @include pad {
    margin-left: -8px;
    width: calc(100% + 16px);
  }
}

.nw-flex-row {
  flex-direction: row;
}
.nw-flex-row-reverse {
  flex-direction: row-reverse;
}
.nw-flex-column {
  flex-direction: column;
}
.nw-flex-column-reverse {
  flex-direction: column-reverse;
}
.nw-align-items-start {
  align-items: flex-start;
}
.nw-align-items-center {
  align-items: center;
}
.nw-align-items-end {
  align-items: flex-end;
}
.nw-justify-content-start {
  justify-content: flex-start;
}
.nw-justify-content-center {
  justify-content: center;
}
.nw-justify-content-end {
  justify-content: flex-end;
}
.nw-justify-content-between {
  justify-content: space-between;
}

@for $i from 1 through 12 {
  .nw-col-#{$i} {
    @include pad(min) {
      width: #{floor(calc(100% / 12) * $i * 100) * 0.01};
    }
  }
  .nw-mcol-#{$i} {
    @include pad {
      width: #{floor(calc(100% / 12) * $i * 100) * 0.01};
    }
  }
}
[class*="nw-col-"],
[class*="nw-mcol-"] {
  padding: 0 15px;
  @include pad {
    padding: 0 8px;
  }
}

// 手機版、電腦版
*.nw-mobile {
  display: none;
  @include pad {
    display: block;
  }
}
*.nw-computer {
  @include pad {
    display: none;
  }
}
// 框架
[id^="app-"] {
  padding: 80px 0;
  @include pad {
    padding: 40px 0;
  }
  .kc-row-container.kc-container {
    @include container;
  }
  &.no-padding-bottom {
    padding-bottom: 0;
  }
  &.no-padding-top {
    padding-top: 0;
  }
  &.nw-default {
    padding: 0;
    .kc-row-container.kc-container {
      padding: 0;
      max-width: 100%;
      width: 100%;
    }
  }
}

// 其他預設版型
// .kc_text_block.nw-default{

// }
.kc_text_block.app-content{
  h6{
    a{
      display: inline-block;
      border: solid 1px $orange;
      color: $orange;
      font-size: rem(22);
      line-height: rem(25);
      padding: 12px 30px;
      text-transform: none;
      font-weight: normal;
      text-decoration: none;
      @include t;
      &:hover{
        background-color: $orange;
        color: $white;
      }
    }
  }
}