@include app(page-about){
  .kc_row_inner{
    display: flex;
    align-items: flex-end;
    position: relative;
    margin-bottom: 80px;
    .kc_single_image{
      img{
        display: block;
        width: 100%;
      }
    }
    .kc_text_block{
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10% 8%;
      margin-left: -100px;
      margin-bottom: -30px;
      width: calc(100% + 100px);
      background-color: #eaeaea;
      box-shadow: 0 8px 5px rgb(0 0 0 / 30%);
      position: relative;
      z-index: 2;
      min-height: 250px;
      @include pad{
        margin-left: 0;
        width: 100%;
        margin-top: 10px;
        padding: 30px;
        min-height: 0;
      }
      p{
        margin-bottom: 15px;
        font-size: rem(16);
        letter-spacing: 1px;
        color: $black;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
    }
    &:nth-of-type(2n+1){
      flex-direction: row-reverse;
      .kc_text_block{
        margin-left: 0;
      }
    }
    &:last-of-type{
      margin-bottom: 0;
    }
  }
}

@include app(page-location){
  background-image: url(/wp-content/uploads/about-bg.png);
  background-size: cover;
  .kc_row_inner{
    .kc_text_block{
      @include pad{
        margin-top: 20px;
      }
      h3{
        margin-bottom: 20px;
        font-size: rem(20);
        letter-spacing: 2px;
        color: $orange;
      }
      p{
        font-size: rem(16);
        margin-bottom: 10px;
        + h3{
          margin-top: 30px;
        }
      }
    }
    .kc_single_image{
      img{
        display: block;
        width: 100%;
      }
    }
  }
}